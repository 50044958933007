import React from 'react';
import HomeSplash from '../../Components/HomeSplash';
import productList from '../../Misc/productList';
import ProductSection from '../../Components/ProductSection';
import IntegrationSection from '../../Components/IntegrationSection';
import Carousel from '../../Components/Carousel';
import BenefitSection from '../../Components/BenefitSection';

const C2MBackgroundImage = '../../Images/main-bg.jpg';

function Home() {
    return (
        <>
            <HomeSplash />
            <div class="main-bg">
                <h1 style={{ margin: "8rem", textAlign: "center" }}>Our Products</h1>
                {productList.map((product, index) => {
                    return <ProductSection key={`product_${index}`} side={index % 2 ? 'product-left' : 'product-right'} {...product} />
                })}
            </div>
            <IntegrationSection />
            {/* <Carousel slides={[<p>Slide 0</p>, <p>Slide 1</p>, <p>Slide 2</p>, <p>Slide 3</p>]}/> */}
            <BenefitSection />
        </>
    );
}

export default Home;