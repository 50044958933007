import HomeSplashCTA from "../HomeSplashCTA";
import HomeSplashImage from "../HomeSplashImage";
import C2MImage from '../../Images/c2m-main.png';
import './style.scss';

export default function HomeSplash() {
    return (
        <div className='home-splash'>
            <div className='container'>
                <HomeSplashCTA />
                <HomeSplashImage />
            </div>
            <div className='image-container'>
                <img className='' src={C2MImage} alt="" />
            </div>
        </div>
    )
}