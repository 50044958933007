import React from 'react';

function Terms() {
    return (
        <>
            <div class="main-bg" style={{width: '100%'}}>
                <div class="container">
                <p
                    style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "8.0pt",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Aptos",sans-serif'
                    }}
                >
                    September 1, 2021
                    <br />
                    <strong>
                    PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. THESE TERMS AND
                    CONDITIONS MAY HAVE CHANGED SINCE YOUR LAST VISIT TO THIS WEB SITE. BY
                    USING ANY OF THE SERVICES, YOU CONFIRM YOUR ACCEPTANCE OF THESE TERMS AND
                    CONDITIONS. IF YOU ARE ENTERING INTO THIS AGREEMENT ON BEHALF OF A COMPANY
                    OR OTHER ENTITY, YOU REPRESENT THAT YOU HAVE THE LEGAL AUTHORITY TO BIND
                    THAT ENTITY TO THESE TERMS AND CONDITIONS. IF YOU DO NOT HAVE SUCH
                    AUTHORITY OR DO NOT ACCEPT THESE TERMS AND CONDITIONS, THEN YOU MAY NOT
                    USE THE SERVICES
                    </strong>
                    <br />
                    <u>
                    <br />
                    &nbsp;CLOUDGATE TECHNOLOGIES
                    </u>
                    <br />
                    <u>TERMS AND CONDITIONS</u>
                </p>
                <ol style={{ marginBottom: "0in", marginTop: "0in" }} start={1} type={1}>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Provision of Product. Cloudgate Technologies will provide Customer with
                    access to its proprietary software service offering products (each a
                    “Product”) as specifically set forth on an ordering document executed by
                    the parties in connection with this Agreement (each an “Order Form”)
                    subject to, and in accordance with, the terms and conditions of this
                    Agreement. An Order Form may cover more than one Product, and more than
                    one Order Form may be in place during the term of this Agreement. Terms of
                    this Agreement specific to a Product will be deemed to apply separately to
                    each Product ordered. As to certain Products, the Order Form may also
                    indicate a specific data partner from which data is to be provided for use
                    with the Product (“Data Partner”). In order to use the Product, Customer
                    is responsible at Customer’s own expense for (a) providing Cloudgate
                    Technologies with access to the API of the Data Partner in order to allow
                    Cloudgate Technologies to access Customer’s data, if applicable; and (b)
                    for providing its own access to the Internet, either directly or through
                    devices that access Web-based content, and for paying any fees associated
                    with such access.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    License Grant. Subject to the terms and conditions of this Agreement, and
                    in consideration for the payment of fees set forth on the Order Form,
                    Cloudgate Technologies hereby grants to Customer a non-exclusive, non-
                    transferable license for Customer’s Users (as defined below) to access and
                    use the Product with respect to the number of Users for which Customer has
                    paid fees (“Authorized Users”), solely for Customer’s internal business
                    purposes and solely for the specific Product offering indicated on the
                    Order Form. A “User” shall mean an employee, or independent contractor of
                    Customer. This license is restricted to use by Customer (or its applicable
                    Permitted Affiliates, as defined below, subject to the requirements in the
                    next sentence below) and its Users and does not include the right to use
                    Cloudgate Technologies Technology (as hereinafter defined) on behalf of
                    any third party or the right to permit any non-User to access or use the
                    Product. Customer may permit its wholly-owned subsidiaries or other
                    affiliates it controls (“Permitted Affiliates”) to exercise its rights or
                    perform its obligations hereunder; provided that (i) such Permitted
                    Affiliates agree to be bound by the terms and conditions of this Agreement
                    as if they were “Customer” herein (and Customer’s execution of the Order
                    Form shall be deemed to be on behalf of itself and such Permitted
                    Affiliates for this purpose); and (ii) all acts and omissions of such
                    Permitted Affiliates (for clarity, including such Permitted Affiliates’
                    personnel) shall be deemed to be acts and omissions of Customer and
                    Customer shall be responsible therefor. Customer also agrees to be bound
                    by any further restrictions set forth on the Order Form. All rights not
                    expressly granted to Customer are reserved by Cloudgate Technologies and
                    its licensors. There are no implied rights.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Provisioning. Cloudgate Technologies will create and assign a login
                    ID/authorized user ID for Customer and allow Customer to select Customer’s
                    password to enable Customer to administer online access by Customer’s
                    Authorized Users. The number of Authorized Users may not exceed the number
                    of Authorized Users authorized by Cloudgate Technologies, and for each
                    defined user category (sales managers, sales representatives), if
                    applicable, as set forth on the Order Form and paid for by Customer.
                    Customer will identify certain Authorized Users who will have
                    administrative, security and supervisory capacities with respect to
                    Customer’s online access to the Product.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Cloudgate Technologies Technology. In connection with the delivery of the
                    Product, Cloudgate Technologies shall operate and support the hosted
                    environment used by Cloudgate Technologies to deliver the Product,
                    including, without limitation, the Cloudgate Technologies Technology.
                    “Cloudgate Technologies Technology” means all of Cloudgate Technologies’s
                    proprietary technology (including software, hardware, products, processes,
                    algorithms, user interfaces, know-how, techniques, designs and other
                    tangible or intangible technical material or information) made available
                    to Customer by Cloudgate Technologies in providing the Product, but for
                    clarity, excludes Customer Data and Customer Confidential Information.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Support. During the term of this Agreement, Cloudgate Technologies will
                    provide Customer with Cloudgate Technologies’s standard support for the
                    Product at no additional cost.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Downtime. Subject to the terms and conditions of this Agreement, Cloudgate
                    Technologies shall use commercially reasonable efforts to provide access
                    to the Product for twenty-four (24) hours a day, seven (7) days a week
                    throughout the term of this Agreement. Customer agrees that from time to
                    time the Product may be inaccessible or inoperable for various reasons,
                    including (i) equipment malfunctions; (ii) periodic maintenance procedures
                    or repairs which Cloudgate Technologies may undertake from time to time
                    (“Scheduled Maintenance”); or (iii) causes beyond the control of Cloudgate
                    Technologies or which are not reasonably foreseeable by Cloudgate
                    Technologies, including interruption or failure of telecommunication or
                    digital transmission links, hostile network attacks or network congestion
                    or other failures (collectively “Downtime”). Cloudgate Technologies shall
                    use commercially reasonable efforts to provide at least two weeks notice,
                    but in no case shall it provide less than 5 days notice of such Scheduled
                    Maintenance. Cloudgate Technologies will exercise reasonable efforts to
                    perform Scheduled Maintenance during business downtime.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Cloudgate Technologies shall have no obligation during performance of such
                    operations to mirror Customer Data on any other server or to transfer
                    Customer Data to any other server. Cloudgate Technologies shall use
                    commercially reasonable efforts to minimize any disruption,
                    inaccessibility and/or inoperability of the Product in connection with
                    Downtime, whether scheduled or not.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Restrictions. Unauthorized use (including any resale or commercial
                    exploitation) of the Product or any Cloudgate Technologies Technology in
                    any way is expressly prohibited. Customer shall not (and shall not allow
                    any third party to): reverse engineer, decompile, disassemble, or
                    otherwise attempt to derive the source code form or structure of any
                    Cloudgate Technologies Technology, or access the Product in order to build
                    a competitive product or service or copy any ideas, features or functions
                    of the Product. Customer shall not copy, license, sell, transfer, make
                    available, lease, time-share, distribute, or assign the Product or any
                    Cloudgate Technologies Technology to any third-party. Customer shall take
                    all measures necessary to ensure compliance by all of its Users with all
                    terms and conditions of this Agreement, and Customer shall be responsible
                    for all acts and omissions of such Users in connection with this
                    Agreement. Customer shall keep all passwords safe and secure, and shall be
                    responsible for all use of the Product using passwords issued to Customer
                    and its Users. In addition to Cloudgate Technologies’s other remedies
                    hereunder, Cloudgate Technologies reserves the right upon ten (10) days
                    prior written notice to Customer to terminate any User’s right to access
                    the Product if Cloudgate Technologies can reasonably demonstrate to
                    Customer that such User has materially violated the restrictions contained
                    in this Agreement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Cloudgate Technologies Ownership. Customer acknowledges and agrees that
                    (i) as between Cloudgate Technologies and Customer, all right, title and
                    interest in and to the Cloudgate Technologies Technology and the Product
                    and all derivatives thereof (including any and all patents, copyrights,
                    trade secret rights, trademarks, trade names and other intellectual
                    property and proprietary rights embodied therein or associated therewith)
                    are and shall remain Cloudgate Technologies’s or its licensors’, and this
                    Agreement in no way conveys any right or interest in the Cloudgate
                    Technologies Technology or the Product other than a limited license to use
                    the Product in accordance herewith, and (ii) the Cloudgate Technologies
                    Technology and the Product are works protected by copyright, trade secret,
                    and other proprietary rights and laws. The Cloudgate Technologies name,
                    the Cloudgate Technologies logo, and the product names associated with the
                    Product are trademarks of Cloudgate Technologies or third parties, and no
                    right or license is granted to use them. Customer shall not remove any
                    Cloudgate Technologies trademark or logo from the Product.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Payments.
                    <br />
                    &nbsp;(a) Fees. Customer shall pay to Cloudgate Technologies the fees
                    specified and in accordance with the schedule set forth on the applicable
                    Order Form. If Customer usage (e.g., number of Authorized Users or Data
                    Sources) expands beyond what is authorized under the applicable Order
                    Form, Customer will be billed immediately and agrees to pay for the
                    additional usage pursuant to Cloudgate Technologies’s then-current fees
                    and payment terms (unless other pricing for such additional usage is
                    specifically set forth in the applicable Order Form). If Customer pays
                    fees by credit card, Cloudgate Technologies will charge the credit card
                    the applicable fee in advance, at the frequency set forth in the Order
                    Form. Customer remains liable for any fees which are rejected by the card
                    issuer or charged back to Cloudgate Technologies. If fees are invoiced,
                    payment is due thirty days from date of invoice (unless otherwise
                    specified on the Order Form). Customer agrees to pay a late charge of one
                    and half percent (1 1/2%) per month (or part of a month), or the maximum
                    lawful rate permitted by applicable law, whichever is less, for all
                    amounts not paid when due and not subject to a good faith dispute raised
                    in a timely manner.
                    <br />
                    &nbsp;(b) Taxes. Amounts set forth on the Order Form exclude taxes.
                    Customer will be solely responsible for, and will promptly pay, all taxes
                    of any kind (including but not limited to sales and use taxes) associated
                    with this Agreement, the Products, or Customer’s and its Users access to
                    the Product, except for taxes based on Cloudgate Technologies’s net income
                    or payroll.
                    <br />
                    &nbsp;(c) Travel Expenses. Customer shall reimburse Cloudgate Technologies
                    for out-of-pocket travel and living expenses, to the extent requested or
                    approved by Customer, within thirty days after invoice by Cloudgate
                    Technologies.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    CustomerData.
                    <br />
                    &nbsp;(a) Customer will provide, upload or import into the Product, and/or
                    permit Cloudgate Technologies to remotely access, upload and import, data,
                    information, materials and processes as necessary and required for the
                    Product to perform its functionality (“Customer Data ”) including, without
                    limitation, Customer Data generated, maintained and/or stored by third
                    party applications used by Customer, which may include the Data Partner
                    application(s) (“Third Party Applications”). The sources of the Customer
                    Data, whether from Customer or Third Party Applications are collectively
                    “Data Sources” for purposes of this Agreement.
                    <br />
                    &nbsp;(b) Customer grants Cloudgate Technologies a worldwide,
                    non-exclusive and royalty-free, limited and revocable license to access,
                    copy, use, store, host, make derivative works of, adapt, display, perform,
                    transmit and distribute to Cloudgate Technologies such Customer Data under
                    or in connection this Agreement only as necessary and required for the
                    Product to perform its functionality for Customer. Customer represents and
                    warrants that Customer has obtained all necessary permissions, rights and
                    consents for all Customer Data provided to Cloudgate Technologies,
                    including without limitation the right to access, upload and import
                    Customer Data from Third Party Applications and other Data Sources, under
                    this Agreement to enable Cloudgate Technologies to provide the Product
                    functionality for Customer.
                    <br />
                    &nbsp;(c) All Customer Data will be stored in a private and secure
                    fashion, and will not be used by Cloudgate Technologies except as
                    necessary to provide the Product (or as set forth in Section 10(f) below).
                    Cloudgate Technologies shall operate the Product in a manner that provides
                    reasonable information security for Customer Data, using commercially
                    reasonable data backup, security, and recovery protections.
                    <br />
                    &nbsp;(d) Except as provided in this Agreement, Customer shall be solely
                    responsible for providing, updating, uploading and maintaining all
                    Customer Data. The content of Customer Data shall be Customer’s sole
                    responsibility.
                    <br />
                    &nbsp;(e) Even though Cloudgate Technologies backs up data on its servers
                    on a regular basis, Customer understands and agrees that it can and should
                    retain copies of its own files and Customer Data and not rely solely on
                    Cloudgate Technologies as the only back up source for Customer’s files and
                    Customer Data. Under no circumstances will Cloudgate Technologies be
                    liable to anyone for damages of any kind under any legal theory for loss
                    of Customer Data.
                    <br />
                    &nbsp;(f) Cloudgate Technologies may use Customer Data to create Aggregate
                    Data (as hereafter defined), and Cloudgate Technologies may during and
                    after the term hereof, use and disclose Aggregate Data in its discretion.
                    “Aggregate Data” means any and all data produced from the Customer’s use
                    of the Product for broad groups or categories, and statistical information
                    and metadata related to Customer’s use of the Product, which in each case
                    do not in any manner identify the Customer or any characteristics of
                    individual persons.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Customer Responsibilities. Customer is solely responsible for all Customer
                    Data. Cloudgate Technologies does not guarantee the accuracy, integrity or
                    quality of such Customer Data. Customer shall not: (a) upload or otherwise
                    make available to Cloudgate Technologies any Customer Data that is
                    unlawful or that violates the rights of any third parties; (b) upload or
                    otherwise make available to Cloudgate Technologies any Customer Data that
                    Customer does not have a right to transmit under any law or under
                    contractual relationships; (c) use, upload or otherwise transmit any
                    Customer Data or domain name that infringes any patent, trademark, trade
                    secret, copyright or other proprietary rights of any person; (d) knowingly
                    upload or otherwise make available to Cloudgate Technologies any material
                    that contains software viruses or any other computer code, files or
                    programs designed to interrupt, destroy or limit the functionality of any
                    computer software or hardware or telecommunications equipment; (e)
                    interfere with or disrupt the Product or Servers or networks connected to
                    the Product; (f) upload or otherwise make available to Cloudgate
                    Technologies any Customer Data that constitutes protected health
                    information subject to the Health Insurance Portability and Accountability
                    Act or any regulation, rule or standards issued thereunder; or (g) violate
                    any applicable law or regulation, including, but not limited to,
                    regulations promulgated by the Federal Trade Commission, any rules of any
                    securities exchange, and laws regarding the export of software or
                    technical data.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Warranties.
                    <br />
                    &nbsp;(a) Customer represents and warrants that: (i) Customer Data and its
                    use by Cloudgate Technologies as permitted by this Agreement will not
                    infringe on the copyrights, trademarks, service marks, patents, or other
                    intellectual property or personal rights held by any third party; (ii)
                    Customer has all corporate (or other organization type) power and
                    authority to enter into this Agreement and has duly and validly authorized
                    this Agreement; and (iii) Customer shall comply with all laws, regulations
                    and rules applicable to Customer and its use of the Product.
                    <br />
                    &nbsp;(b) Customer shall not, and shall not knowingly permit others to,
                    (i) remove any proprietary notices or labels on the Product (including
                    without limitation, any copyright, trademark notices), or (ii) use the
                    Product other than as expressly permitted hereunder. (c) Cloudgate
                    Technologies represents and warrants that (i) it will provide the Product
                    in a competent and workmanlike manner; (ii) it has all corporate power and
                    authority to enter into this Agreement and has duly and validly authorized
                    this Agreement, and (iii) it shall comply with all laws, regulations and
                    rules applicable to provision of the Product. Cloudgate Technologies does
                    not warrant that it will be able to correct all reported defects or that
                    use of the Product will be uninterrupted or error free. Cloudgate
                    Technologies makes no warranty regarding features or products provided by
                    Data Partner or any other third parties. Cloudgate Technologies retains
                    the right to modify its products and services at its discretion provided
                    that doing so does not have a material adverse impact on the Product
                    hereunder. Customer’s sole remedy for Cloudgate Technologies’s breach of
                    the warranty in clause (c)(i) above shall be that Cloudgate Technologies
                    shall remedy the applicable error, or if Cloudgate Technologies is unable
                    to do so in a timely manner, refund to Customer actual damages up to a
                    limit of the fees paid for the Product for the period during which the
                    breach of warranty occurred. (d) Customer acknowledges and agrees that
                    except for the express warranties provided above in this Section 12, all
                    warranties, whether express, implied or statutory, and all obligations and
                    representations as to performance, including all warranties which might
                    arise from course of dealing or custom or trade and including all implied
                    warranties of merchantability or fitness for a particular purpose, are
                    hereby expressly excluded and disclaimed by Cloudgate Technologies. No
                    Data Partner makes any representations or warranties with respect to
                    Cloudgate Technologies’s performance under this agreement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Limitation of Liability. Except for its indemnification obligations
                    hereunder (Section 14), Customer’s breach of Section 7 (Restrictions),
                    confidentiality obligations of each of Customer and Cloudgate Technologies
                    (each a “Party”) (Section 19), gross negligence or willful misconduct, or
                    customer’s payment obligations hereunder:
                    <br />
                    &nbsp;(i) to the fullest extent permissible by law, each Party’s aggregate
                    liability for all damages arising out of or related to this agreement,
                    whether in contract, tort (including negligence) or otherwise, shall not
                    exceed the amount of fees paid by Customer to Cloudgate Technologies under
                    this Agreement during the prior six (6) months; and
                    <br />
                    &nbsp;(ii) in no event shall either Party (or for Cloudgate Technologies,
                    its licensors or other providers) be liable for indirect, consequential,
                    special, incidental, exemplary or punitive damages (which may include the
                    loss of anticipated profits or revenues) arising out of or related to this
                    Agreement, whether in contract, tort (including negligence), or otherwise.
                    <br />
                    &nbsp;The Parties agree that the limitations of liability set forth in
                    this section 13 are a fundamental basis of the bargain, that Cloudgate
                    Technologies has set its fees in reliance on the enforceability of these
                    provisions, and that they shall apply notwithstanding that any limited
                    remedy or this agreement shall fail its essential purpose.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Indemnification.
                    <br />
                    &nbsp;(a) Cloudgate Technologies shall defend, indemnify and hold harmless
                    Customer, its affiliates, and their respective directors, officers,
                    employees and agents (collectively, “Related Persons”) from and against
                    any liabilities, losses, damages, costs or expenses, including reasonable
                    attorneys’ fees, experts’ fees and court costs (collectively, “Losses”) to
                    the extent resulting from any third party claim, demand, suit or
                    proceeding (each, a “Claim”) based on any allegation that the Product, as
                    provided by Cloudgate Technologies hereunder, infringes or misappropriates
                    any copyright or trade secret of such third party. If a claim of
                    infringement or misappropriation occurs, or if Cloudgate Technologies
                    determines that a claim is likely to occur, Cloudgate Technologies shall
                    have the right, in its sole discretion, to either: (i) procure for
                    Customer the right or license to continue to use the Product free of the
                    infringement claim; or (ii) replace or modify the Product to make it
                    non-infringing. If these remedies are not reasonably available to
                    Cloudgate Technologies, Cloudgate Technologies may, at its option,
                    terminate this Agreement and return to Customer any pre-paid unused fees
                    for the Product. Despite the provisions of this Section, Cloudgate
                    Technologies has no obligation with respect to any claim of infringement
                    or misappropriation that is based upon or arises out of (x) Customer’s use
                    of the Product other than in accordance with the applicable documentation
                    or Cloudgate Technologies’s written directions or policies; or (y) any
                    third party software, other technology, data or content, or any Customer
                    Data. For any infringement by the Product, this section states the entire
                    liability of Cloudgate Technologies and Customer’s sole and exclusive
                    remedies.
                    <br />
                    &nbsp;(b) Customer shall defend, indemnify and hold harmless Cloudgate
                    Technologies and its Related Persons from and against any Losses to the
                    extent resulting from any Claim based on any allegation that the Customer
                    Data infringes, violates or misappropriates such third party’s
                    intellectual property or proprietary rights.
                    <br />
                    &nbsp;(c) As conditions to the indemnification obligations in paragraphs
                    (a) and (b) above, the indemnified party shall (i) promptly notify the
                    indemnifying party in writing of any Claim for which indemnity is claimed,
                    provided that failure to so notify will not relieve the indemnifying
                    party’s indemnification obligation except to the extent it is prejudiced
                    thereby; (ii) allow the indemnifying party sole control, at its expense,
                    of the defense and settlement of the Claim, provided that (A) the
                    indemnified party may participate in such defense and settlement via
                    counsel of its own choosing, at its sole expense, and (B) any settlement
                    shall be subject to the prior written consent of the indemnified party,
                    not unreasonably withheld; and (iii) reasonably cooperate with the
                    indemnifying party with respect to such defense and settlement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Assignment. This Agreement will bind and inure to the benefit of each
                    party’s successors and permitted assigns. Neither party shall, without the
                    prior written consent of the other party, assign or transfer this
                    Agreement, in whole or in part, provided that either party may assign this
                    Agreement in its entirety, without consent, to any successor to its
                    business, including in connection with any merger, consolidation, and any
                    sale of all or substantially all of its assets or any other transaction in
                    which more than fifty percent (50%) of the party’s voting securities are
                    transferred, provided that such successor agrees in writing to be bound by
                    the terms of this Agreement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Entire Agreement. This Agreement, including the Order Form, contains the
                    complete understanding and agreement of the parties and supersedes all
                    prior or contemporaneous agreements or understandings, oral or written
                    (including confidentiality agreements), relating to the subject matter
                    herein. Notwithstanding any course of dealings between the parties at any
                    time, no purchase order, invoice or similar document shall be construed to
                    modify any of the terms of this Agreement, unless the document is (a)
                    signed by both parties and (b) expressly refers to all provisions of this
                    Agreement that the parties intend to modify by such document. This
                    Agreement may be executed electronically or in multiple counterparts, all
                    of which, taken together, shall constitute one and the same instrument.
                    Delivery of an executed signature page by facsimile or email shall be
                    deemed effective as delivery of a manually-executed counterpart. Any
                    conflict between the terms set forth on an Order Form and this Agreement
                    shall be controlled by the terms and conditions set forth in this
                    Agreement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Termination and Suspension. This Agreement takes effect on the Effective
                    Date indicated in the initial Order Form and shall continue until
                    completion for the period of performance set forth in the Order Form and
                    through completion of the period of performance set forth in any
                    subsequent Order Form, if later. Unless otherwise set forth on the Order
                    Form, at the end of each such period of performance, each Order Form will
                    automatically renew for successive terms of 24 months, unless either party
                    gives written notice of non- renewal at least 30 days before the renewal
                    date. Notwithstanding the foregoing, each party will have the right to
                    terminate this Agreement if the other party materially breaches this
                    Agreement and fails to cure such breach within thirty (30) days after
                    written notice thereof. In addition to the foregoing, Cloudgate
                    Technologies also reserves the right, in its sole and absolute discretion,
                    to suspend providing the Product and Customer agrees that Cloudgate
                    Technologies may shut off and suspend Customer’s access to the Product at
                    any time, without having to terminate this Agreement, if Customer is more
                    than thirty (30) days late with respect to any undisputed payments due
                    hereunder, subject to being provided written notice of such payment
                    delinquency by Cloudgate Technologies. Upon such suspension, Customer
                    shall still be liable for all payments that have accrued prior to the date
                    of suspension. Cloudgate Technologies will not be obligated to restore
                    access to the Product until Customer has paid all fees owed to Cloudgate
                    Technologies.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    After Termination. Upon any termination or expiration of this Agreement:
                    (i) all licenses and rights granted hereunder shall terminate and
                    Cloudgate Technologies shall no longer provide access to the Product to
                    Customer and
                    <br />
                    &nbsp;(ii) Customer shall cease and cause its Users to cease using the
                    Product. Upon termination of this Agreement by Cloudgate Technologies due
                    to Customer’s uncured breach, in addition to any other remedies Cloudgate
                    Technologies may have for such breach, Customer shall pay Cloudgate
                    Technologies for all fees that had accrued prior to the termination date.
                    Except as expressly provided herein, termination of this Agreement by
                    either party will be a nonexclusive remedy for breach and will be without
                    prejudice to any other right or remedy of such
                    <br />
                    &nbsp;party. Sections 7, 8, and 13-22, and any other terms related to
                    interpretation of this Agreement or which by their nature should remain
                    applicable, and all accrued payment obligations, will survive termination
                    or expiration of this Agreement.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Confidentiality. Each Party agrees that during the term of this Agreement,
                    information that is confidential may be disclosed to the other Party,
                    including, but not limited to Cloudgate Technologies Technology, Customer
                    Data, software, technical processes and formulas, source codes, product
                    designs, sales, cost and other unpublished financial information, product
                    and business plans, advertising revenues, projections, and marketing data
                    (“Confidential Information”). Confidential Information shall not include
                    information that the receiving Party can demonstrate (a) is generally
                    known to the public at the time of its disclosure, or thereafter becomes
                    generally known to the public through a source other than the receiving
                    Party, (b) was known to the receiving Party as of the time of its
                    disclosure, (c) is independently developed by the receiving Party, or (d)
                    is subsequently learned from a third party not under a confidentiality
                    obligation to the providing Party. Except as provided for in this
                    Agreement, each Party shall not make any disclosure of the other Party’s
                    Confidential Information to anyone other than its employees, contractors
                    or Users who have a need to know in connection with this Agreement. Each
                    Party shall notify its employees, contractors and Users of their
                    confidentiality obligations with respect to the other Party’s Confidential
                    Information and shall require its employees, contractors and Users to
                    comply with these obligations. The confidentiality obligations of each
                    Party and its employees and Users shall survive three years beyond the
                    expiration or termination of this Agreement. Cloudgate Technologies shall
                    have the right to publicly use Customer’s name and logo on lists published
                    on Cloudgate Technologies’s website and in marketing materials. Cloudgate
                    Technologies may announce the customer relationship in a press release
                    provided that Cloudgate Technologies obtains Customer’s prior approval of
                    the release and the wording of the release, not unreasonably withheld.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Notices. Any notice required or permitted hereunder shall be in writing
                    and may be delivered to the contact person listed on the Order Form as
                    follows (with notice deemed given as indicated): (i) by personal delivery
                    when delivered personally; (ii) by established overnight courier upon
                    written verification of receipt; (iii) by certified or registered mail,
                    return receipt requested, upon verification of receipt; or (iv) by
                    electronic delivery when receipt is confirmed orally. Either party may
                    change its contact person for notices and/or address for notice by means
                    of notice to the other party given in accordance with this Section.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    Force Majeure. If either party is prevented from performing any of its
                    obligations under this Agreement (except for Customer’s obligation to pay
                    amounts due to Cloudgate Technologies) due to any cause beyond the party’s
                    reasonable control, including, without limitation, an act of God, fire,
                    flood, explosion, war, strike, embargo, government regulation, civil or
                    military authority, acts or omissions of carriers, transmitters,
                    providers, or acts of vandals (each, a “Force Majeure Event”), the time
                    for that party’s performance will be extended for the period of the delay
                    or inability to perform due to such occurrence; provided that, if a party
                    suffering a Force Majeure Event is unable to resume performance within
                    thirty (30) days, the other party may terminate this Agreement by written
                    notice thereof.
                    </li>
                    <li
                    style={{
                        marginTop: "0in",
                        marginRight: "0in",
                        marginBottom: "8.0pt",
                        marginLeft: "0in",
                        fontSize: "11.0pt",
                        fontFamily: '"Aptos",sans-serif'
                    }}
                    >
                    General Provisions. This Agreement shall be interpreted according to the
                    laws of the Commonwealth of Massachusetts without regard to or application
                    of choice-of-law rules or principles. The prevailing party shall have the
                    right to collect from the other party its costs and expenses, including
                    reasonable attorneys fees, incurred in enforcing this Agreement. No
                    failure of either party to exercise or enforce any of its rights under
                    this Agreement shall act as a waiver of any breaches and the waiver of any
                    breach shall not act as a waiver of subsequent breaches. In the event any
                    provision of this Agreement is held by a court or other tribunal of
                    competent jurisdiction to be unenforceable, that provision will be
                    enforced to the maximum extent permissible under applicable law, and the
                    other provisions of this Agreement will remain in full force and effect.
                    The parties further agree that in the event such provision is an essential
                    part of this Agreement, they will begin negotiations for a replacement
                    provision. Neither this Agreement nor the Order Form (and other schedules
                    and exhibits thereto) may be construed as creating or constituting a
                    partnership, joint venture, or agency relationship between the parties. Neither party
                    will have the power to bind the other or incur obligations on the other’s
                    behalf without the other’s prior written consent. This Agreement shall be
                    construed and interpreted, in accordance with the plain meaning of its
                    terms, and there shall be no presumption or inference against the party
                    drafting this Agreement in construing or interpreting the provisions
                    hereof.
                    </li>
                </ol>
                <p
                    style={{
                    marginTop: "0in",
                    marginRight: "0in",
                    marginBottom: "8.0pt",
                    marginLeft: "0in",
                    fontSize: "11.0pt",
                    fontFamily: '"Aptos",sans-serif'
                    }}
                >
                    &nbsp;
                    </p>
                </div>
            
            </div>
        </>
    );
}

export default Terms;