import './style.scss';

export default function BenefitsCard({ item }) {
    return (
        <div className='benefit-card'>
            <img src={item.image} alt=""></img>
            <h1 className='title'>{item.title}</h1>
            <h4 className='description'>{item.description}</h4>
        </div>
    )
}