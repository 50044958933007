import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { NavProvider } from './Contexts/NavContext';
import NavBar from './Components/NavBar';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import Products from './Pages/Products';
import Company from './Pages/Company';
import Login from './Pages/Login';
import Terms from './Pages/Terms';

function App() {
  return (
    <>
      <Router>
        <NavProvider>
          <NavBar />
          <Switch>
            <Route path="/" component={Home} exact/>
            <Route path="/products" component={Products} exact/>
            <Route path="/company" component={Company} exact/>
            <Route path="/login" component={Login} exact/>
            <Route path="/terms" component={Terms} exact/>
          </Switch>
          <Footer />
        </NavProvider>
      </Router>
    </>
  );
}

export default App;
