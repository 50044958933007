import React from 'react';

function Company() {
    return (
        <>
            <h1>Company</h1>
        </>
    );
}

export default Company;