import React from 'react';
import integrationImage from '../../Images/arc-chart.png';
import './style.scss';

function IntegrationSection() {
    return (
        <div className={'integration-container'}>
            <h1 className={'title'}>Effortlessly Integrate With Bullhorn</h1>
            <p>Optimize your data from Bullhorn into the cloud and unlock the gate to<br />
        maximum workflow efficiency.</p>
            <img src={integrationImage}></img>
        </div>
    )
}

export default IntegrationSection;