import BenefitsCard from '../BenefitsCard';
import SaveTime from '../../Images/save-time.png';
import Personalize from '../../Images/personalize.png';
import Engage from '../../Images/engage.png';
import Support from '../../Images/support.png';
import  './style.scss';

export default function BenefitSection() {

    const benefits = [{
        title: 'Save Time',
        image: SaveTime,
        description: 'Plug and Play'
    }, {
        title: 'Personalize',
        image: Personalize,
        description: 'The Data You Need Most'
    }, {
        title: 'Engage',
        image: Engage,
        description: 'Insights and Data'
    }, {
        title: 'Support',
        image: Support,
        description: 'Customer Focused Resources'
    }];

    const benefitCards = benefits.map((item, index) => {
        return <BenefitsCard key={index} item={item} />
    });

    return (
        <div className='benefit-section'>
            <div className='container'>
                {benefitCards}
            </div>
        </div>
    )
}