import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavContext } from '../../Contexts/NavContext';
import cloudgateLogo from '../../Images/cloudgate-logo.png';
import './style.scss';

function NavBar() {
    const { pages } = useContext(NavContext);
    const location = useLocation();
    return (
        <div className="navbar">
            <div className="logo-wrapper">
                <img className="logo" src={cloudgateLogo} alt="" />
                <span className="logo-text">CloudGate</span>
            </div>
            <div className="navigation">
                {Object.entries(pages).map(([pageName, pagePath]) => {
                    // if (pagePath === location.pathname) return null;
                    return <Link className="nav-link" key={`navbar_${pagePath}`} to={pagePath}>{pageName}</Link>
                })}
            </div>
        </div>
    );
}



export default NavBar;