import C2MImage from '../Images/c2m-complete.png';
import CompImage from '../Images/comp-tracker-complete.png';
const productList = [
    {
        name: 'C2M',
        heading: 'Pipeline intelligence',
        points: [
            <>A full-stack solution designed to help<br/>recruiting teams add more value and<br/><span className="highlight">increase</span> bottom-line production.</>,
            <><span className="highlight">C2M</span> = Closest to the Money</>,
            <>Less time logging = more time doing</>
        ],
        image: {
            className: 'c2m-image',
            src: C2MImage,
            alt: ''
        }
    },
    {
        name: <><span className="product-name-section">Compensation</span><br/><span className="product-name-section">Tracker</span></>,
        heading: 'Financial Transparency',
        points: [
            <>Financial Transparency</>,
            <>Say <span className="highlight">goodbye to tracking commission</span><br/>and bonus payouts with a standard<br/>spreadsheet.</>,
            <>Less time accounting = more time<br/>growing</>
        ],
        image: {
            className: 'comp-image',
            src: CompImage,
            alt: ''
        }
    }
];

export default productList;