import React, { useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { NavContext } from '../../Contexts/NavContext';
import './style.scss';
import Logo from '../../Images/cloudgate-logo.png'

function Footer() {
    const { pages } = useContext(NavContext);
    const location = useLocation();
    return (
        <div className="footer">
            <img className="logo" src={Logo} alt="" />
            <p className="logo-text">CloudGate</p>
            <div className="bottom-text">
                {/* <div className="footer-links">
                {Object.entries(pages).map(([pageName, pagePath]) => {
                    if (pagePath === location.pathname) return null;
                    return <Link className="footer-link" key={`footer_${pagePath}`} to={pagePath}>{pageName}</Link>
                })}
                </div> */}
                <Link className="footer-link" key={`terms`} to={'terms'}>{'Terms and Conditions'}</Link>
                <br />
                <p className="copyright">&copy;2024 Cloudgate. All rights reserved.</p>
            </div>
        </div>
    )
}

export default Footer;