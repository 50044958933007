import MessageBubbles from '../../Images/talk-bubble.png';
import BullhornApp from '../../Images/bull-exchange-tablet.png'
import './style.scss';

export default function HomeSplashImage() {
    return (
        <div className='image-wrapper'>
            <img className='message-bubbles' src={MessageBubbles} alt=""/>
            <p>
                <b>Speak to an optimization consultant</b><br />
                in order to maximize pipeline effeciency<br />
            </p>
            <img className='bullhorn-app' src={BullhornApp} alt=""/>
        </div>
    )
}