import { createContext } from 'react';

const pages = {
    Home: '/',
    Products: '/#',
    Company: '/#',
    Login: '/#'
}

const NavContext = createContext(pages);

function NavProvider(props) {
    return (
        <NavContext.Provider value={{ pages }}>
            {props.children}
        </NavContext.Provider>
    )
}

export { NavContext, NavProvider };