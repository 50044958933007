import React from 'react';
import './style.scss';

function ProductSection(props) {
    return (
        <>
            <div className={`product-section ${props.side}`}>
                <div className="product-copy">
                    <h1 className="product-name">{props.name}</h1>
                    <h2 className="product-heading">{props.heading}</h2>
                    <ul className="product-points">
                        {props.points.map((point, index) => <li className="product-point" key={`${props.name}_point_${index}`}>{point}</li>)}
                    </ul>
                </div>
                <img className={`product-image ${props.image.className}`} src={props.image.src} alt={props.image.alt} />
            </div>
        </>
    )
}

export default ProductSection;