import './style.scss';

export default function HomeSplashCTA() {
    return (
        <div className='cta-wrapper'>
            <h1>
                VISUALIZE,<br />
                COLLABORATE, INTERACT<br />
            </h1>
            <h2>
                WITH YOUR PIPELINE LIKE NEVER<br />
                BEFORE<br />
            </h2>
            <h3>
                The Cloud Ecosystem for your Bullhorn environment!
            </h3>
            <button>SEE HOW IT WORKS</button>
        </div >
    )
}