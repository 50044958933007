import React from 'react';

function Products() {
    return (
        <>
            <h1>Products</h1>
        </>
    );
}

export default Products;